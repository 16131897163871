import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  plugins: [createPersistedState()],
  state : {
    modalData : null, // { title, imgSrc, description, link, idx },
    modalIndex : 0,
    headerMenuIndex: 1,
    section5ScrollTop: 0,
    section5BtnState: false,
  },
  mutations: {
    setModalData(state, data) {
      state.modalData = data
      if (data) {
        state.modalIndex = data.idx
      } else {
        state.modalIndex = 0
      }
    },
    setModalIndex(state, data) {
      state.modalIndex = data
    },
    increaseModalIndex(state) {
      state.modalIndex++
    },
    decreaseModalIndex(state) {
      state.modalIndex--
    },
    setHeaderMenuIndex(state, value) {
      state.headerMenuIndex = value;
    },
    resetHeaderMenuIndex(state) {
      state.headerMenuIndex = 1;
    },
    setSection5ScrollTop(state, value) {
      state.section5ScrollTop = value;
    },
    changeSection5BtnState(state, value) {
      state.section5BtnState = value;
    }
  }
});
