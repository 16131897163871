<template>
  <div>
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>
import { onMounted,  } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'App',
  components: {
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      store.commit('resetHeaderMenuIndex');
      store.commit('setSection5ScrollTop', 0);
      store.commit('changeSection5BtnState', false);
    })
    return {
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
