import { createRouter, createWebHistory } from "vue-router";



const router = createRouter({
  history: createWebHistory(""),
  routes: [    
    {      
      path: "/",
      name: "Index",
      component: () => import("../components/IndexPage.vue"),
    },
    {      
      path: "/news/:id",
      name: "News",      
      component: () => import("../components/NewsPage.vue"),
    },
    {
      path: "/news",
      redirect: { path: "/", query: { sectionNumber: '05' } }
    }
  ],
}); 

export default router;
